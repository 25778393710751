export class PWA  {

      private _url:string;
      
        constructor(  url  : string ) {
	    this._url = url ;
      }
      
      isStandAlone () {
          return  window.matchMedia('(display-mode: standalone)').matches;
      }
      async update ( ){
                   
      const registration = await window.navigator.serviceWorker.getRegistration();

          if (!registration) {
        return;
        }
        const result = await registration.unregister();
        window.location.href = this._url ; 
        
      }
}

import { defineComponent } from "vue";

import { PWA } from "../modules/app/pwa";


import Push from "@/components/Push.vue";

export default defineComponent({
  name: "AppSettings" ,
  data (){
    return {

      fileserveruser : {
        username : "",
        password :""

      },
      isShowPush :false,
        openaiApiKey : "",
        chatgptPrescripts: [] as any,
        openaiModels: [],
        selectedOpenaiModel : "", 
        newMenuTitle : "",
        isShowLicense : false , 
        currentVersion: "",
        newVersion: "",
        isPWA : false , 
        pwa : new PWA("/pwa/"),
        licenses : {} , 
        showRouterCount : 0  ,
        showSecretMode : false ,
        path : ""
    }  
  },
   
   mounted  : async function (this:any){

          
    const urlParams = new URLSearchParams(window.location.search);
  
  // クエリパラメータに "push" が含まれているか確認
  if (urlParams.has('push')) {
    this.isShowPush = true;  // "push" がある場合に許可する
  } else {
    this.isShowPush = false; // "push" がない場合は非表示にする
  }
  
          this.isLoggedin =(window as any).isLoggedin;

          if(window.matchMedia('(display-mode: standalone)').matches){
          
             this.isPWA = true;
           }
            this.$emit('show-header');         
           
          (window as any).getCacheId(
            (id:string)=>{ 
              
              this.currentVersion = id; 
            },
            (e:any)=>{
              this.currentVersion ="-";
              console.error(e);
            }
          );

            const res = await fetch("./license.csv", {"cache":"no-cache"});
            var input =await res.text( );
            input = input.replaceAll(" ","_").replaceAll("\"","");
            const lines = input.split('\n') // 
            const header = lines[0].split(',') // 
            
            this.licenses = lines.slice(1).map(line => {
            
              const fields = line.split(',') // 
              return Object.fromEntries(header.map((h, i) => [h, fields[i]])) // 
            });


  },
  methods: {
    

      initSettings  : async function (this:any){
  /*open ai settings*/            
       

       try{
            
            const r=await fetch ( process.env.VUE_APP_APP_API_V4_URL + "/file/info" );
            this.fileserveruser = await r.json();
            }catch(e){console.error(e);

          }

       try {
        
            const models = await ( await fetch(  process.env.VUE_APP_APP_API_V4_URL  + "openai/models") ) .json(); 
            console.log(models);
            this.openaiModels = models.models.filter( (v:string)=>{ return  v.includes("gpt")  } ).filter( (v:string)=>{ return  !v.includes("instruct")  } );
            if (this.selectedOpenaiModel=="") {  this.selectedOpenaiModel =this.openaiModels[0]; }
        } catch (e){ console.log(e ); } 

        try {
           const exists = await ( await fetch(  process.env.VUE_APP_APP_API_V4_URL  + "openai/apikey") ) .json(); 
           if ( exists.exists ) {  this.openaiApiKey ="api key exists.";  }
       } catch (e){ console.log(e ); } 
      
      try{
            const configuration = await ( await fetch(  process.env.VUE_APP_APP_API_V4_URL  + "openai/configuration") ) .json(); 
            this.selectedOpenaiModel = configuration.gptmodel ;
            for( const i in configuration.prescript){
                this.chatgptPrescripts.push(configuration.prescript [i]  );
            }
           this.chatgptPrescripts.push({role:"system",content:""});
            console.log(  this.chatgptPrescripts);
        } catch (e){ console.log(e ); } 
    }, 
      addChatgptPrescript( i:number ){
          const tmp  =  { role:"system",content:"" } ;
           this.chatgptPrescripts.splice(i+1, 0,tmp );
          
      },
      reduceChatgptPrescript( i:number ){
          this.chatgptPrescripts.splice(i , 1);
      },
      async  saveOpenAIAPIKey( this:any ){
          
          if (this.openaiApiKey =="api key exists." ){
                this.$emit('show-flash',{ "message":"APIKeyは登録済みです。","type": "warning"});  
              return ;
          }
          
          const conf:any={  apikey : this.openaiApiKey  };
          
          const res = await ( await fetch( 
           process.env.VUE_APP_APP_API_V4_URL  + "openai/apikey", 
             { method: "POST",   headers: { "Content-Type": "application/json" },body : JSON.stringify( conf ) }  )) .json(); 
     try{
        if(res.success){   this.$emit('show-flash',{ "message":"設定を更新しました。","type": "success"});   }
        }catch (e){ console.log(e);}
          
      },
          
      async saveOpenAIConf ( this:any ){
          
          const conf:any={ 
            gptmodel : this.selectedOpenaiModel , 
            prescript : this.chatgptPrescripts.filter( (v:any )=> v.content!=="" )
          };
          
          const res = await ( await fetch( 
           process.env.VUE_APP_APP_API_V4_URL  + "openai/configuration", 
             { method: "POST",   headers: { "Content-Type": "application/json" },body : JSON.stringify( conf ) } 
             )) .json(); 
     try{
        if(res.success){   this.$emit('show-flash',{ "message":"設定を更新しました。","type": "success"});   }
        }catch (e){ console.log(e);}
          
      },
      
       async dumpDB (this:any){
          
          const Sql = await  ( window as any ).getSql();
        
        Sql.dumpDB( (data:any)=>{
            
             const buffer = new Uint8Array(data);

    // Uint8ArrayからBlobオブジェクトを作成
    const blob = new Blob([buffer], { type: 'application/octet-stream' });

    // ダウンロードリンクを作成
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'paletteiot.sqlite';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
           
        } );
      },
      
      showFlash(o:any){
        
        this.$emit("show-flash",o);     
        
      },
     async exportMenu ( this:any ){
          
       let text =JSON.stringify(   this.$parent.$parent.dashboard  ) ;     
       const blob = new Blob([text], { type: 'text/plain' });
  const aTag = document.createElement('a');
  aTag.href = URL.createObjectURL(blob);
  aTag.target = '_blank';
  aTag.download = "menu.palettejson";
  aTag.click();
  URL.revokeObjectURL(aTag.href);
      
      },
      async importMenu ( this:any ){
          

                 try{
            
            const el:any =  window.document.getElementById("file-menu");
            const file  =el.files[0];

            var option:any = {}  ;
            option.method = "PUT";
            option.body =  await file.text() ;
            option.header =  { "Content-Type": "application/json"};

            let res = await fetch (  process.env.VUE_APP_APP_API_V4_URL + 'dashboard/setting' ,
            option );      
           
           // let d = await res.json();  
           if ( res.ok   ){
                this.$emit('show-flash',{ "message":"メニューを設定しました。","type": "success"});     
                 
                 setTimeout( ()=>{
                      window.location.href = '/pwa/';      
                 }, 5000);

           }    
            
          } catch (e:any){
         
              console.log(e);
               this.$emit('show-flash',{ "message":"設定に失敗しました。","type": "warning"});  
         } 
          
      },
      addMenu :function(this:any){
          
          this.$emit('add-menu',{title: this.newMenuTitle, "menu-title": this.newMenuTitle,path:  "/custom/dashboard/"+this.newMenuTitle,"date-search": true,
		"aggregate": true,
		"particlesize": true,
		"initial-particlesize": "1hour",
		"initial-aggregate": "median",
		"editmode" : true , 
		"tabs":[]
		 });        
	  
		 this.newMenuTitle = ""; 
      },
      showRouterBtn : async function( this:any ){
     
     this.showRouterCount ++ ;
    
    if( 30 < this.showRouterCount ) {
        
        this.showSecretMode = true;
        
        await this.initSettings();
    }
   },
   editCustomCSS (this:any){

      this.$emit('show-edit-css', { show:true , path : "*" });  
           
   },
    pathTo (this:any){
      
        this.$router.push(this.path); 
    },
    isOnline :function (){
      return (window as any ).navigator.onLine ;
    },
    isNewVersionAvailable: function(){
      return ( window as any).isNewVersionAvailable;
    },
    update : function (this:any){

        const loadingScreen = document.getElementById('loading-screen');
  
  if (loadingScreen) {
    loadingScreen.style.display = 'flex';
  
  if ((window as any ).navigator.serviceWorker && (window as any ).navigator.serviceWorker.controller) {
     (window as any ). navigator.serviceWorker.getRegistration().then((registration:any) =>{
      if (registration && registration.waiting) {
        
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });  // 新しいService Workerに切り替え

         registration.waiting.addEventListener('statechange', (event:any) => {
          if (event.target.state === 'activated') {
            
             localStorage.setItem("buildVersion",this.newVersion);

            this.$emit('show-flash',{ "message":"アプリを更新しました。","type": "success"}); 
            console.log('Service Worker activated. Redirecting...');
           
            setTimeout(()=>{ 
              loadingScreen.style.display = 'none';
              (window as Window).location.href = "/pwa"; 
              },3000)
  
          } 
          
        });
      }
    }
  );
  }

  /*
      (window as any).updateServiceWorker(
        (b:boolean
        // ,m:string
        )=>{
        loadingScreen.style.display = 'none';
        
          if(b){
          
            this.$emit('show-flash',{ "message":"アプリを更新しました。","type": "warning"});  
               
            localStorage.setItem("buildVersion",this.newVersion);

            setTimeout(()=>{ (window as Window).location.href = "/pwa"; },3000)
          }else {
          
            this.$emit('show-flash',{ "message":"アプリの更新はありませんでした。","type": "warning"});  
          }
        },
        (e:Error)=>{
          console.log(e);
          this.$emit('show-flash',{ "message":"更新に失敗しました。","type": "warning"});  
          loadingScreen.style.display = 'none';
        });
        */
      }
    }
  }
  ,components:{Push}
});
